import {
    ECOVADISPROGRAM__NUM_OF_ASSESED_SUPPLIERS,
    ECOVADISPROGRAM__SPEND_AMOUNT_COVERED,
    ECOVADISPROGRAM__NUM_OF_SUPPLIERS_TO_BE_REDUCED,
    ECOVADISPROGRAM__NUM_OF_FRAMEWORKS
} from "../actions/actionTypes";

const ecovadisProgramReducer = (state, action) => {
    switch (action.type) {
        case ECOVADISPROGRAM__NUM_OF_ASSESED_SUPPLIERS:
            return {...state, ecovadisProgram: {...state.ecovadisProgram, numOfAssesedSuppliers: action.payload}};
        case ECOVADISPROGRAM__SPEND_AMOUNT_COVERED:
            return {...state, ecovadisProgram: {...state.ecovadisProgram, spendAmountCovered: action.payload}};
        case ECOVADISPROGRAM__NUM_OF_SUPPLIERS_TO_BE_REDUCED:
            return {...state, ecovadisProgram: {...state.ecovadisProgram, numOfSuppliersToBeReduced: action.payload}};
        case ECOVADISPROGRAM__NUM_OF_FRAMEWORKS:
            return {...state, ecovadisProgram: {...state.ecovadisProgram, numOfFrameworks: action.payload}};
        default:
            return state;
    }
};

export default ecovadisProgramReducer;