import React, {Component} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import {connect} from "react-redux";
import {Button} from 'reactstrap';
import '../scss/_select.scss';

import ExcelExport from './excel-export';


am4core.useTheme(am4themes_animated);

/**
 * Interactive amCharts component
 * @class
 * @hideconstructor
 */
class InteractiveChart extends Component {

    /**
     * Preparing blank data before render
     * @method
     */
    componentDidMount() {

        var data = [
            {"category": "Growth\nand Innovation", "Direct impact": 0, "Avoided impact": 0},
            {"category": "Employee\nEngagement", "Direct impact": 0, "Avoided impact": 0},
            {"category": "Cost Reduction", "Direct impact": 0, "Avoided impact": 0},
            {"category": "Cost of Capital", "Avoided impact": 0, "Direct impact": 0},
            {"category": "Revenue loss", "Avoided impact": 0, "Direct impact": 0},
            {"category": "Cost increase", "Avoided impact": 0, "Direct impact": 0},
            {"category": "Market cap", "Avoided impact": 0, "Direct impact": 0},
        ];

        let chart = am4core.create("chartdiv", am4charts.XYChart);

        chart.data = data;
        chart.numberFormatter.numberFormat = "#.000a";
        chart.numberFormatter.bigNumberPrefixes = [
            { "number": 1e+3, "suffix": "K" },
            { "number": 1e+6, "suffix": "M" },
            { "number": 1e+9, "suffix": "B" }
        ];
        chart.contentHeight = 900;
        chart.paddingTop = 10;

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        configureCategoryAxis(categoryAxis);
        createRange(categoryAxis, "Avoided", "Revenue loss", "Market cap", '#4e93f9');
        createRange(categoryAxis, "Direct", "Growth\nand Innovation", "Cost of Capital", '#fcb315');

        let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
        configureValueAxis(valueAxis1, false, .7);

        let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        configureValueAxis(valueAxis2, true, .1);

        let series_direct = chart.series.push(new am4charts.ColumnSeries());
        configureSerie(series_direct, "Direct impact", "Direct impact", valueAxis1, "#fcb315", "$");

        let series_avoided = chart.series.push(new am4charts.ColumnSeries());
        configureSerie(series_avoided, "Avoided impact", "Avoided impact", valueAxis2, "#4e93f9", "$");

        chart.legend = new am4charts.Legend();
        chart.legend.interactionsEnabled = false;
        chart.legend.position = "top";
        chart.legend.marginBottom = 15;
        chart.legend.labels.template.marginRight = 20;
        chart.legend.labels.template.valign = "bottom";

        // Add vertical scrollbar
        chart.scrollbarY = new am4core.Scrollbar();
        chart.scrollbarY.marginLeft = 0;
        chart.scrollbarY.marginRight = 0;
        chart.scrollbarY.exportable = false;

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomY";
        chart.cursor.exportable = false;
        chart.cursor.lineX.disabled = true;

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "bottom";
        chart.exporting.filePrefix = "EcoVadis Sustainable Procurement ROI";

        this.chart = chart;

        function configureCategoryAxis(categoryAxis) {
            categoryAxis.dataFields.category = "category";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 30;
            categoryAxis.renderer.labels.template.horizontalCenter = "left";
            categoryAxis.renderer.labels.template.verticalCenter = "middle";
            categoryAxis.renderer.labels.template.rotation = 45;
            categoryAxis.renderer.fontSize = 14;
            categoryAxis.cursorTooltipEnabled = false;
        }

        function configureValueAxis(valueAxis, opposite, extraMax) {
            valueAxis.renderer.fontSize = 10;
            valueAxis.renderer.opposite = opposite;
            valueAxis.title.fontSize = 12;
            valueAxis.min = 0;
            valueAxis.layout = "absolute";
            valueAxis.extraMax = extraMax;
            chart.numberFormatter.numberFormat = "#.#a";
            chart.numberFormatter.bigNumberPrefixes = [
                { "number": 1e+3, "suffix": "K" },
                { "number": 1e+6, "suffix": "M" },
                { "number": 1e+9, "suffix": "B" }
            ];

            valueAxis.title.text = "$";
            valueAxis.title.rotation = 0;

            if (opposite) {
                valueAxis.title.align = "left";
                valueAxis.title.marginLeft = 8;
            } else {
                valueAxis.title.align = "right";
                valueAxis.title.marginRight = 8;
            }

            valueAxis.title.valign = "top";
            valueAxis.title.dy = -40;
        }

        function configureSerie(serie, name, valueY, valueAxis, color, currency) {
            serie.dataFields.valueY = valueY;
            serie.dataFields.categoryX = "category";
            serie.yAxis = valueAxis;
            serie.columns.template.fill = am4core.color(color);
            serie.name = name;
            serie.clustered = false;
            serie.columns.template.tooltipHTML = "{categoryX}:<br/><b>" + currency + "{valueY}</b>";
            serie.tooltip.fontSize = 14;
            serie.tooltip.exportable = false;

            serie.tooltip.label.adapter.add("htmlOutput", (text, target, key) => {
                if (target.dataItem) {
                    if (target.dataItem.values.valueY.value === 0) {
                        return "";
                    }
                }
                return text;
            });
        }

        function createRange(categoryAxis, name, startCategory, endCategory, color) {
            let axisRange = categoryAxis.axisRanges.create();

            axisRange.axisFill.interactionsEnabled = true;
            axisRange.category = startCategory;
            axisRange.endCategory = endCategory;
            axisRange.height = .3;

            axisRange.grid.disabled = true;
            axisRange.label.text = "Total: $0";
            axisRange.label.dy = -20;
            axisRange.label.interactionsEnabled = false;
            axisRange.label.inside = true;
            axisRange.label.valign = "top";
            axisRange.label.location = 0.5;
            axisRange.label.rotation = 0;
            axisRange.label.horizontalCenter = "middle";

            axisRange.axisFill.fill = color;
            axisRange.axisFill.disabled = false; // as regular fills are disabled, we need to enable this one
            axisRange.axisFill.fillOpacity = 0.1;
            axisRange.axisFill.togglable = true;

            axisRange.axisFill.showSystemTooltip = true;
            axisRange.axisFill.readerTitle = "click to zoom";
            axisRange.axisFill.cursorOverStyle = am4core.MouseCursorStyle.pointer;

            axisRange.axisFill.events.on("hit", function (event) {
                var dataItem = event.target.dataItem;
                if (!event.target.isActive) {
                    categoryAxis.zoom({start: 0, end: 1});
                } else {
                    categoryAxis.zoomToCategories(dataItem.category, dataItem.endCategory);
                }
            });

        }
    }

    /**
     * Destroying chart after the component lifespan is done
     * @method
     */
    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    /**
     * Updating the data on the chart on every state change
     * @method
     */
    componentDidUpdate() {
        this.updateData();
    }

    /**
     * Update chart method
     * @method
     */
    updateData = () => {
        const {data} = this.chart;
        const {calculations} = this.props;
        const {currency} = this.props.financialData;

        data[0]["Direct impact"] = calculations.growthAndInnovation;
        data[1]["Direct impact"] = calculations.employeeEngagement;
        data[2]["Direct impact"] = calculations.costReduction;
        data[3]["Direct impact"] = calculations.lowerCostOfCapital;
        data[4]["Avoided impact"] = calculations.revenueLossAvoidance;
        data[5]["Avoided impact"] = calculations.costIncreaseAvoidance;
        data[6]["Avoided impact"] = calculations.marketCapLossAvoidance;

        let totalDirect = calculations.totalDirect,
            totalAvoided = calculations.totalAvoided;
        let numberFormatter = new am4core.NumberFormatter();
        numberFormatter.bigNumberPrefixes = [
            { "number": 1e+3, "suffix": "K" },
            { "number": 1e+6, "suffix": "M" },
            { "number": 1e+9, "suffix": "B" }
        ];

        // Total values
        this.chart.xAxes.values[0].axisRanges.values[0].label.text = "Total: " + currency + numberFormatter.format(totalAvoided, "#.000a");
        this.chart.xAxes.values[0].axisRanges.values[1].label.text = "Total: " + currency + numberFormatter.format(totalDirect, "#.000a");

        this.chart.series.values[0].tooltipHTML = "{categoryX}:<br/><b>" + currency + "{valueY}</b>";
        this.chart.series.values[1].tooltipHTML = "{categoryX}:<br/><b>" + currency + "{valueY}</b>";

        // Currencies
        this.chart.yAxes.values[0].title.text = currency;
        this.chart.yAxes.values[1].title.text = currency;
        this.chart.invalidateRawData();
    };

    /**
     * Handling export of the chart file
     * @param {String} ext Extension of document chosen by user
     * @method
     */
    handleExport = (ext) => {
        if (ext === 'png') {
            this.chart.exporting.export(ext)
                .then()
        }
    };

    render() {
        const ButtonsContainer = () => (
            <div className="buttons-container">
                <ExcelExport />
                <Button onClick={() => {
                    this.handleExport('png');
                }}
                >Export as PNG</Button>
            </div>
        );

        return (
            <section className="interactive-chart">
                <div id="chartdiv" />
                <ButtonsContainer />
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {...state};
};

export default connect(mapStateToProps)(InteractiveChart);