import React, {Component} from 'react';
import StepsTile from './steps-tile';

/**
 * Steps component
 * @class
 * @hideconstructor
 */
class Steps extends Component {

    render() {
        const headers = this.props.headers;

        return (
           <section className="steps" id="steps-bar">
               {headers.map((header, i) => (
                   <StepsTile
                       key={i}
                       step={i+1}
                       stepHeader={header}
                   />
               ))}
            </section>
        );
    }
}

export default Steps;
