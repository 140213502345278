import {
    FINANCIALDATA__CURRENCY,
    FINANCIALDATA__ANNUAL_REVENUE,
    FINANCIALDATA__REVENUE_GROWTH_FORECAST,
    FINANCIALDATA__OPERATING_MARGIN,
    FINANCIALDATA__OPERATING_EXPENSES,
    FINANCIALDATA__MARKET_CAP,
    FINANCIALDATA__ANNUAL_CORPORATE_BORROWING,
    FINANCIALDATA__PRODUCT_PORTFOLIO
} from "../actions/actionTypes";

const companyInfoReducer = (state, action) => {
    switch (action.type) {
        case FINANCIALDATA__CURRENCY:
            return {...state, financialData: {...state.financialData, currency: action.payload}};
        case FINANCIALDATA__ANNUAL_REVENUE:
            return {...state, financialData: {...state.financialData, annualRevenue: action.payload}};
        case FINANCIALDATA__REVENUE_GROWTH_FORECAST:
            return {...state, financialData: {...state.financialData, revenueGrowthForecast: action.payload}};
        case FINANCIALDATA__OPERATING_MARGIN:
            return {...state, financialData: {...state.financialData, operatingMargin: action.payload}};
        case FINANCIALDATA__OPERATING_EXPENSES:
            return {...state, financialData: {...state.financialData, operatingExpenses: action.payload}};
        case FINANCIALDATA__MARKET_CAP:
            return {...state, financialData: {...state.financialData, marketCap: action.payload}};
        case FINANCIALDATA__ANNUAL_CORPORATE_BORROWING:
            return {...state, financialData: {...state.financialData, annualCorporateBorrowing: action.payload}};
        case FINANCIALDATA__PRODUCT_PORTFOLIO:
            return {...state, financialData: {...state.financialData, productPortfolio: action.payload}};
        default:
            return state;
    }
};

export default companyInfoReducer;