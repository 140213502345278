import React, {Component} from 'react';
import {
    FormGroup,
    Button,
    Container,
    Row,
    Col,
    Label
} from 'reactstrap';
import FormHeader from './form-header';
import FormInput from './form-input';
import {connect} from "react-redux";
import {setUserID, setApiSaved, setUserExistence} from "../store/actions/actionCreators";
import axios from "axios";

/**
 * Form component for current step from Redux store
 * @class
 * @hideconstructor
 */
class FormStep extends Component {

    /**
     * Save data and proceed to next step
     * @param {Object} e Passed event
     * @method
     */
    saveAndContinue = (e) => {
        e.preventDefault();
        this.props.proceedToNextStep();
    };

    /**
     * Render fields from current step function
     * @method
     */
    renderFields = () => {
        const {formSteps} = this.props;

        return formSteps.fields.map(
            (field) => (
                <Col xs={field.sizes.xs} sm={field.sizes.sm} md={field.sizes.md} key={field.id}>
                    <FormGroup
                        className={field.id === "FINANCIALDATA__LABEL" ? "labeled" : ""}
                    >
                        <Label for={field.id}>{field.label}</Label>
                        <FormInput field={field} />
                    </FormGroup>
                </Col>
            )
        )

    };

    render() {
        const {formSteps, state: {steps, calculations, companyInfo, financialData, api, basicInfo}} = this.props;
        const name = companyInfo.name.trim();
        const email = companyInfo.email.trim();
        const companyName = basicInfo.name.trim();
        const currency = financialData.currency.trim();
        const industry = basicInfo.industry.trim();
        if (steps === 1 && api.dataSaved) {
            this.props.setUserExistence(false);
            this.props.setUserID("0");
        }
        if (steps < 5 && api.dataSaved) {
            this.props.setApiSaved(false);
        }
        if (steps === 5 && (!api.dataSaved) && name.length > 0 && email.length > 0) {
            // TODO: debug, remove in PROD
            console.log('debug - user exists: ', api.userExists);
            let dataToPush = {...calculations, user_name: name, user_email: email, company_name: companyName, industry: industry, currency: currency};
            const url = api.userExists ? 'https://roicalculator.ecovadis.com/api/updateUser' : 'https://roicalculator.ecovadis.com/api/addNewUser';
            console.log(url);
            if (api.userExists)
                dataToPush = {...dataToPush, id: api.userID};

            axios.post(
                url,
                dataToPush,
                {
                    headers: {'Content-Type': 'application/json'}
                })
                .then(res => {
                    const {data} = res;
                    if (data) {
                        // TODO: debug, remove in PROD
                        console.log('debug - result data', data);
                        this.props.setApiSaved(true);
                        this.props.setUserExistence(true);
                        this.props.setUserID(data.user.id);
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        }
        return (
            <Container>
                <Row form>
                    <Col sm={12}>
                        <FormHeader title={formSteps.formHeader} tooltip={formSteps.formHeaderTooltipText} />
                    </Col>
                </Row>
                <Row form>
                    {this.renderFields()}
                </Row>
                <Row form>
                    <Col sm={12} className="text-sm-center text-md-left">
                        {steps !== 5 ? <Button onClick={this.saveAndContinue}>Proceed To Next Step</Button> : null}
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {state}
};

const mapDispatchToProps = dispatch => {
    return {
        setApiSaved: (payload) => dispatch(setApiSaved(payload)),
        setUserExistence: (payload) => dispatch(setUserExistence(payload)),
        setUserID: (payload) => dispatch(setUserID(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormStep);