import React, {Component} from 'react';
import {ReactComponent as Arrow} from '../assets/images/arrow.svg';
import {Link} from 'react-router-dom';
import axios from 'axios';

import {proceedToStepAction, setUserExistence, setUserID} from '../store/actions/actionCreators';
import {connect} from 'react-redux';

/**
 * Navigation Component
 * @class
 * @hideconstructor
 */
class Navigation extends Component {

    /**
     *  Proceed to previous step
     *  @method
     */
    proceedToPrevStep = () => {
        const {steps: currentStep} = this.props.state;
        const nextStep = currentStep === 1 ? 1 : currentStep - 1;

        const formWrapper = document.querySelector('.form-wrapper');
        formWrapper.classList.add('hidden');
        setTimeout(() => {
            this.props.proceedToStep(nextStep);
        }, 300);

    };

    /**
     * Proceed to next step
     * @method
     */
    proceedToNextStep = () => {
        const {steps: currentStep, companyInfo} = this.props.state;
        const nextStep = (currentStep === 5 ? 5 : currentStep + 1);
        const name = companyInfo.name.trim();
        const email = companyInfo.email.trim();

        if (currentStep === 1 && currentStep !== nextStep && name.length > 0 && email.length > 0) {
            axios.post(
                'https://roicalculator.ecovadis.com/api/checkIfUserExists',
                {
                    "user_name": name,
                    "user_email": email
                },
                {
                    headers: {'Content-Type': 'application/json'}
                })
                .then(res => {
                    const {data} = res;
                    console.log('user existence', data);
                    if (data.id !== "0") {
                        this.props.setUserExistence(true);
                        this.props.setUserID(data.id);
                    } else this.props.setUserExistence(false);
                })
                .catch(error => {
                    console.log(error)
                });
        }

        const formWrapper = document.querySelector('.form-wrapper');
        formWrapper.classList.add('hidden');
        setTimeout(() => {
            this.props.proceedToStep(nextStep);
        }, 300);

    };

    render() {
        const {steps} = this.props.state;

        const style = {
            display: 'inline-block'
        };

        const displaySkipButton = (step) => {
            if (step === 5)
                style.display = 'none';
            else
                style.display = 'inline-block';

            return style;
        };

        const BackButton = () => {
            if (steps === 1) {
                return <Link to='/what-is-roi'>
                            <Arrow />
                            Back
                        </Link>
            } else {
                return <button onClick={this.proceedToPrevStep}>
                            <Arrow />
                            Back
                       </button>
            }
        };

        return (
            <section className="navigation">
                <div>
                    <BackButton />
                </div>
                <div>
                    <button style={displaySkipButton(steps)} onClick={this.proceedToNextStep}>
                        Skip
                        <Arrow />
                    </button>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {state}
};

const mapDispatchToProps = dispatch => {
    return {
        proceedToStep: (payload) => dispatch(proceedToStepAction(payload)),
        setUserExistence: (payload) => dispatch(setUserExistence(payload)),
        setUserID: (payload) => dispatch(setUserID(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
