
/* GROWTH AND INNOVATION */

/**
 * Annual incremental revenue growth rate due to improved reputation from sustainability programs
 * @type {Number}
 * @default 0.3%
 * @const
 */
const ANNUAL_INCREMENTAL_REVENUE_GROWTH_RATE = 0.003;

/**
 * Percentage attributable to mature SP program - Revenue growth from improved reputation
 * @type {Number}
 * @default 10%
 * @const
 */
const PER_ATTRIBIUTE_TO_MATURE_SP_REVENUE = 0.1;

/**
 * Average price premium for sustainable products
 * @type {Number}
 * @default 10%
 * @const
 */
const AVG_PRICE_FOR_SUSTAINABLE_PRODUCTS = 0.1;

/**
 * Percentage attributable to mature SP program - Revenue growth from sustainable categories/products
 * @type {Number}
 * @default 5%
 * @const
 */
const PER_ATTR_TO_MATURE_SP = 0.05;

/**
 * Revenue growth from improved reputation
 * @param annualRevenue {Number} Annual Revenue (millions)
 * @return {Number}
 * @function
 */
export const REVENUE_GROWTH_FROM_REP = (annualRevenue) => {
    return annualRevenue *
        ANNUAL_INCREMENTAL_REVENUE_GROWTH_RATE *
        PER_ATTRIBIUTE_TO_MATURE_SP_REVENUE *
        1000000.0;
};

/**
 * Revenue growth from sustainable categories / products
 * @param annualRevenue {Number} Annual Revenue (millions)
 * @param productPortfolio {Number} Percentage of product portfolio with potential for price premium
 * @return {Number}
 * @function
 */
export const REVENUE_GROWTH_FROM_PRODUCTS = (annualRevenue, productPortfolio) => {
    if (annualRevenue !== 0 && productPortfolio !== 0)
        return annualRevenue *
            productPortfolio *
            AVG_PRICE_FOR_SUSTAINABLE_PRODUCTS *
            PER_ATTR_TO_MATURE_SP *
            10000.0;
    else
        return 0;
};

// EMPLOYEE ENGAGEMENT
const COST_OF_HIRING_NEW_EMPLOYEE = 9850;
const PER_OF_RELEVANT_EMPLOYEES_PROCUREMENT = 0.5;
const PER_ATTR_TO_MATURE_SP_EMPLOYEE_PROCUREMENT = 0.25;

const AVG_ATTRITION_RATE_PROCUREMENT = 0.151;
const COST_OF_REPLACING_ONE_EMPLOYEE = 50000;

const AVG_ANNUAL_SALARY = 50000;
const INCREASED_PRODUCTIVITY_COEFFICIENT_PROCUREMENT = 0.16;

const AVG_ATTRITION_RATE = 0.151;
const PER_OF_RELEVANT_EMPLOYEES = 0.05;
const PER_ATTR_TO_MATURE_SP_EMPLOYEE = 0.1;
const INCREASED_PRODUCTIVITY_COEFFICIENT = 0.015;

export const HIRING_EXPENSE_SAVINGS_PROCUREMENT = (numOfEmployeesProcurement, revenueGrowthForecast) => {
    if (numOfEmployeesProcurement !== 0 && revenueGrowthForecast !== 0)
        return Math.round(
            numOfEmployeesProcurement *
            revenueGrowthForecast *
            0.01 *
            COST_OF_HIRING_NEW_EMPLOYEE *
            PER_OF_RELEVANT_EMPLOYEES_PROCUREMENT *
            PER_ATTR_TO_MATURE_SP_EMPLOYEE_PROCUREMENT
        );
    else
        return 0;
};

export const ATTRITION_EXPENSE_SAVINGS_PROCUREMENT = (numOfEmployeesProcurement) => {
    return Math.round(
        numOfEmployeesProcurement *
        AVG_ATTRITION_RATE_PROCUREMENT *
        COST_OF_REPLACING_ONE_EMPLOYEE *
        PER_OF_RELEVANT_EMPLOYEES_PROCUREMENT *
        PER_ATTR_TO_MATURE_SP_EMPLOYEE_PROCUREMENT
    );
};

export const INCREASED_PRODUCTIVITY_PROCUREMENT = (numOfEmployeesProcurement) => {
    return Math.round(
        numOfEmployeesProcurement *
        AVG_ANNUAL_SALARY *
        INCREASED_PRODUCTIVITY_COEFFICIENT_PROCUREMENT *
        PER_OF_RELEVANT_EMPLOYEES_PROCUREMENT *
        PER_ATTR_TO_MATURE_SP_EMPLOYEE_PROCUREMENT
    );
};

export const HIRING_EXPENSE_SAVINGS = (numOfEmployees, revenueGrowthForecast) => {
    if (numOfEmployees !== 0 && revenueGrowthForecast !== 0)
        return Math.round(
            numOfEmployees *
            revenueGrowthForecast *
            0.01 *
            COST_OF_HIRING_NEW_EMPLOYEE *
            PER_OF_RELEVANT_EMPLOYEES *
            PER_ATTR_TO_MATURE_SP_EMPLOYEE
        );
    else
        return 0;
};

export const ATTRITION_EXPENSE_SAVINGS = (numOfEmployees) => {
    return Math.round(
        numOfEmployees *
        AVG_ATTRITION_RATE *
        COST_OF_REPLACING_ONE_EMPLOYEE *
        PER_OF_RELEVANT_EMPLOYEES *
        PER_ATTR_TO_MATURE_SP_EMPLOYEE
    );
};

export const INCREASED_PRODUCTIVITY = (numOfEmployees) => {
    return Math.round(
        numOfEmployees *
        AVG_ANNUAL_SALARY *
        INCREASED_PRODUCTIVITY_COEFFICIENT *
        PER_OF_RELEVANT_EMPLOYEES *
        PER_ATTR_TO_MATURE_SP_EMPLOYEE
    );
};

// COST REDUCTION
const EXTERNAL_REPORTING_ANN_HOURS_SPEND = 150;
const AVG_HOURLY_SALARY = 24;
const REP_EFFICIENCY_RATE = 0.3;
const PER_ATTR_TO_MATURE_SP_STREAMLINING = 0.5;
const SUPPLIER_OPERATIONAL_COST_SAVINGS_RATE = 0.125;
const PER_ATTR_TO_MATURE_SP_SAVINGS = 0.1;
const COST_OF_MANAGING_ONE_SUPPLIER = 1050;
const PER_ATTR_TO_MATURE_SP_REDUCTIONS = 0.25;

export const STREAMLINING_EXISTING_REPORTING_PROCESSES = (numOfFrameworks) => {
    return numOfFrameworks *
        EXTERNAL_REPORTING_ANN_HOURS_SPEND *
        AVG_HOURLY_SALARY *
        REP_EFFICIENCY_RATE *
        PER_ATTR_TO_MATURE_SP_STREAMLINING *
        2;
};

export const SUPPLIER_OPERATIONAL_COST_SAVINGS = (spendAmountCovered) => {
    return spendAmountCovered *
        1000000 *
        SUPPLIER_OPERATIONAL_COST_SAVINGS_RATE *
        PER_ATTR_TO_MATURE_SP_SAVINGS;
};

export const SUPPLIER_REDUCTIONS = (numOfSuppliersToBeReduced) => {
    return numOfSuppliersToBeReduced *
        COST_OF_MANAGING_ONE_SUPPLIER *
        PER_ATTR_TO_MATURE_SP_REDUCTIONS;
};

// COST OF CAPITAL
const INTEREST_REDUCTION_RATE = 0.00425;
const PER_ATTR_TO_MATURE_SP_LOWER_COST = 0.1;

export const LOWER_COST_OF_CAPITAL = (annualCorporateBorrowing) => {
    return annualCorporateBorrowing *
        1000000 *
        INTEREST_REDUCTION_RATE *
        PER_ATTR_TO_MATURE_SP_LOWER_COST;
};

// RISK REDUCTION
const LIKELIHOOD_OF_SCH_INCIDENT = 0.56;
const LIKELY_NUM_OF_SCH_INCIDENTS = 1;
const PROPORTION_OF_SCH_INCIDENTS = 0.23;
const EFFECT_OF_SCH_DISRUPTIONS_ON_REVENUE = 0.09;
const PER_ATTR_TO_MATURE_SP_LOSS_AVOIDANCE = 0.25;
const EFFECT_OF_SSCH_DISRUPTIONS_ON_COSTS = 0.11;

export const REVENUE_LOSS_AVOIDANCE = (annualRevenue) => {
    return Math.round(
        annualRevenue *
        1000000 *
        LIKELIHOOD_OF_SCH_INCIDENT *
        LIKELY_NUM_OF_SCH_INCIDENTS *
        PROPORTION_OF_SCH_INCIDENTS *
        EFFECT_OF_SCH_DISRUPTIONS_ON_REVENUE *
        PER_ATTR_TO_MATURE_SP_LOSS_AVOIDANCE
    );
};

export const COST_INCREASE_AVOIDANCE = (operatingExprenses) => {
    return Math.round(
        operatingExprenses *
        1000000 *
        LIKELIHOOD_OF_SCH_INCIDENT *
        LIKELY_NUM_OF_SCH_INCIDENTS *
        PROPORTION_OF_SCH_INCIDENTS *
        EFFECT_OF_SSCH_DISRUPTIONS_ON_COSTS *
        PER_ATTR_TO_MATURE_SP_LOSS_AVOIDANCE
    );
};

// MARKET CAP
const AVG_REPUTATION_RISK_IMPACT_ON_MC = 0.025;
const PER_ATTR_TO_MATURE_SP_MC = 0.25;

/**
 * Market cap loss avoidance
 * @param {Number} marketCap Market cap (millions)
 * @return {Number} Risk mitigation / Market cap
 * @function
 */
export const MARKET_CAP_LOSS_AVOIDANCE = (marketCap) => {
    return Math.round(
        marketCap *
        1000000 *
        LIKELIHOOD_OF_SCH_INCIDENT *
        AVG_REPUTATION_RISK_IMPACT_ON_MC *
        PER_ATTR_TO_MATURE_SP_MC
    );
};