import React, {Component} from 'react';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';

/**
 * Input tooltip component
 * @class
 * @hideconstructor
 */
class InputTooltip extends Component {
    render() {
        const {text} = this.props;
        return (
            <>
               <Tippy content={text}>
                   <span className="tooltip">i</span>
               </Tippy>
           </>
        );
    }
}

export default InputTooltip;