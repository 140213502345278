export const fields = [
    {
        formHeader: "Tell us about your company",
        formHeaderTooltipText: "",
        fields: [
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12,
                },
                label: "Your Name",
                type: "text",
                id: "COMPANYINFO__NAME",
                placeholder: "Enter Your Name",
                storeRef: "companyInfo.name",
                calcRef: ""
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 6,
                },
                label: "Job Title",
                type: "text",
                id: "COMPANYINFO__JOB_TITLE",
                placeholder: "Job Title",
                storeRef: "companyInfo.jobTitle",
                calcRef: ""
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 6,
                },
                label: "Email Address",
                type: "email",
                id: "COMPANYINFO__EMAIL",
                placeholder: "Email Address",
                storeRef: "companyInfo.email",
                calcRef: ""
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12,
                },
                label: "# of Employees",
                type: "number",
                id: "COMPANYINFO__EMPLOYEES_AMOUNT",
                placeholder: "# of Employees",
                storeRef: "companyInfo.employeesAmount",
                calcRef: "NUM_OF_EMPLOYEES"
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12,
                },
                label: "# of Employees (In Procurement & Supply Chain-Related Roles)",
                type: "number",
                id: "COMPANYINFO__EMPLOYEES_AMOUNT_PROCUREMENT",
                placeholder: "# of Employees",
                storeRef: "companyInfo.employeesAmountProcurement",
                calcRef: "NUM_OF_EMPLOYEES_PROCUREMENT"
            }
        ]
    },
    {
        formHeader: "Now a bit about your financials",
        fields: [
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12,
                },
                type: "hidden",
                id: "FINANCIALDATA__LABEL",
                label: "Annual Revenue (millions)",
                storeRef: "financialData.currency",
                calcRef: ""
            },
            {
                sizes: {
                    xs: 3,
                    sm: 2,
                    md: 2,
                },
                type: "select",
                options: ['$', '€', '£'],
                id: "FINANCIALDATA__CURRENCY",
                label: "",
                placeholder: "$",
                storeRef: "financialData.currency",
                calcRef: ""
            },
            {
                sizes: {
                    xs: 9,
                    sm: 10,
                    md: 10
                },
                type: "number",
                id: "FINANCIALDATA__ANNUAL_REVENUE",
                placeholder: "Annual Revenue (millions)",
                label: "",
                storeRef: "financialData.annualRevenue",
                calcRef: "ANNUAL_REVENUE",
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                label: "Revenue Growth Forecast %",
                type: "number",
                id: "FINANCIALDATA__REVENUE_GROWTH_FORECAST",
                placeholder: "Enter Revenue Growth Forecast",
                storeRef: "financialData.revenueGrowthForecast",
                calcRef: "REVENUE_GROWTH_FORECAST"

            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                label: "Present Operating Margin %",
                type: "number",
                id: "FINANCIALDATA__OPERATING_MARGIN",
                placeholder: "Present Operating Margin",
                storeRef: "financialData.operatingMargin",
                calcRef: "OPERATING_MARGIN"
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 6
                },
                label: "Operating Expenses (millions)",
                type: "number",
                id: "FINANCIALDATA__OPERATING_EXPENSES",
                placeholder: "Operating Expenses (millions)",
                storeRef: "financialData.operatingExpenses",
                calcRef: "OPERATING_EXPENSES"
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 6
                },
                label: "Market Cap (millions)",
                type: "number",
                id: "FINANCIALDATA__MARKET_CAP",
                placeholder: "Market Cap",
                storeRef: "financialData.marketCap",
                calcRef: "MARKET_CAP"
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                label: "Total Annual Corporate Borrowing (millions)",
                type: "number",
                id: "FINANCIALDATA__ANNUAL_CORPORATE_BORROWING",
                placeholder: "Total Annual Corporate Borrowing",
                storeRef: "financialData.annualCorporateBorrowing",
                calcRef: "ANNUAL_CORPORATE_BORROWING"
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                label: "% of product portfolio with potential for sustainability price premium",
                type: "number",
                id: "FINANCIALDATA__PRODUCT_PORTFOLIO",
                placeholder: "%",
                storeRef: "financialData.productPortfolio",
                calcRef: "PERCENTAGE_OF_PRODUCT_PORTFOLIO"
            }
        ]
    },
    {
        formHeader: "Now on to your supply chain",
        fields: [
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                label: "# of Suppliers",
                type: "number",
                id: "SUPPLYCHAINDATA__NUM_OF_SUPPLIERS",
                placeholder: "# of Suppliers",
                storeRef: "supplyChainData.numOfSuppliers",
                calcRef: ""
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                label: "# of Spend Categories",
                type: "number",
                id: "SUPPLYCHAINDATA__NUM_OF_SPEND_CATEGORIES",
                placeholder: "# of Spend Categories",
                storeRef: "supplyChainData.numOfSpendCategories",
                calcRef: ""
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                label: "Spend Amount (millions)",
                type: "number",
                id: "SUPPLYCHAINDATA__NUM_OF_SPEND_AMOUNT",
                placeholder: "Enter Spend Amount (millions)",
                storeRef: "supplyChainData.numOfSpendAmount",
                calcRef: ""
            }
        ]
    },
    {
        formHeader: "Tell us how you would use EcoVadis",
        fields: [
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                label: "# of Suppliers Assessed by EcoVadis",
                type: "number",
                id: "ECOVADISPROGRAM__NUM_OF_ASSESED_SUPPLIERS",
                placeholder: "# of Suppliers Assessed by EcoVadis",
                storeRef: 'ecovadisProgram.numOfAssesedSuppliers',
                calcRef: "NUM_OF_ASSESED_SUPPLIERS"
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                label: "Spend Amount Covered by EcoVadis Program (millions) ",
                type: "number",
                id: "ECOVADISPROGRAM__SPEND_AMOUNT_COVERED",
                placeholder: "Spend Amount Covered by EcoVadis Program (millions)",
                storeRef: 'ecovadisProgram.spendAmountCovered',
                calcRef: "SPEND_AMOUNT_COVERED"
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                label: "# of Suppliers to be Reduced",
                type: "number",
                id: "ECOVADISPROGRAM__NUM_OF_SUPPLIERS_TO_BE_REDUCED",
                placeholder: "# of Suppliers to be Reduced ",
                storeRef: 'ecovadisProgram.numOfSuppliersToBeReduced',
                calcRef: "NUM_OF_SUPPLIERS_TO_BE_REDUCED"
            },
            {
                sizes: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                label: "# of Frameworks & Regulations to be Reported",
                type: "number",
                id: "ECOVADISPROGRAM__NUM_OF_FRAMEWORKS",
                placeholder: "# of Frameworks & Regulations to be Reported",
                storeRef: 'ecovadisProgram.numOfFrameworks',
                calcRef: "NUM_OF_FRAMEWORKS"
            }
        ]
    },
    {
        formHeader: "",
        fields: []
    }
];

export default {
    fields,
}