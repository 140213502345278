import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import DefaultLayout from '../layouts/default-layout.js';
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import Steps from '../components/steps';
import AppForm from '../components/form';
import Navigation from '../components/navigation';
import {stepsHeaders} from '../constants/steps-headers';
import {connect} from 'react-redux';
import InteractiveChart from "../components/interactive-chart";

/**
 * Form Page component
 * @class
 * @hideconstructor
 */
class FormPage extends Component {

    constructor(props) {
        super(props);

        this.resizeWrappers = (direction) => {
            const formWrapper = document.querySelector('.form-wrapper');
            const chartWrapper = document.querySelector('.chart-wrapper');

            if (direction === 'in') {
                formWrapper.classList.add('resized');
                chartWrapper.classList.add('resized');
            } else if (direction === 'out') {
                formWrapper.classList.remove('resized');
                chartWrapper.classList.remove('resized');
            }

        };

        this.showFormWrapper = () => {
            const formWrapper = document.querySelector('.form-wrapper');
            formWrapper.classList.remove('hidden');
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {steps: prevStep} = prevProps.state;
        const {steps: currentStep} = this.props.state;

        if (prevStep !== currentStep) {
            if (prevStep === 5) {
                this.resizeWrappers('out');
                setTimeout(() => this.showFormWrapper(), 700);
            } else if (currentStep === 5) {
                this.resizeWrappers('in');
            } else {
                this.showFormWrapper();
            }
        }
    }


    render() {
        const path = this.props.location.pathname;
        const {name, industry} = this.props.state.basicInfo;

        if (name && industry) {
            return (
                <DefaultLayout path={path}>
                    <main className="form-page">
                        <Container>
                            <Row>
                                <Steps headers={stepsHeaders} />
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <h1>
                                    {name}’s Sustainable Procurement
                                    ROI Forecast
                                </h1>
                            </Row>
                            <Row>
                                <Col className="form-wrapper" xs={12} md={5}>
                                    <AppForm />
                                </Col>
                                <Col className="chart-wrapper col-md" xs={12}>
                                    <InteractiveChart />
                                </Col>
                            </Row>
                            <Row>
                                <Navigation />
                            </Row>
                        </Container>
                    </main>
                </DefaultLayout>
            );
        } else {
            return <Redirect to="/what-is-roi" />;
        }
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {state}
};
export default connect(mapStateToProps)(FormPage);