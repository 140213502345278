import React, {Component} from 'react';
import Header from "../components/navbar";
import Footer from "../components/footer";

/**
 * Default layout component
 * @class
 * @hideconstructor
 */
class DefaultLayout extends Component {

    render() {

        const formClass = (path) => {
            return path === '/form' ? 'form-page' : '';
        };

        return (
            <div className="default-layout">
                <Header isFormPage={formClass(this.props.path)} />
                {this.props.children}
                <Footer isFormPage={formClass(this.props.path)}/>
            </div>
        );
    }
}

export default DefaultLayout;