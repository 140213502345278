import React, {Component} from 'react';
import {connect} from 'react-redux';
import {proceedToStepAction} from '../store/actions/actionCreators';

/**
 * Steps Tile Component
 * @class
 * @hideconstructor
 */
class StepsTile extends Component {

    /**
     * Handle Step Switch
     * @param nextStep {Number} the step to which we proceed (1-5)
     * @method
     */
    handleStepSwitch = (nextStep) => {
        const {steps} = this.props.state;

        const formWrapper = document.querySelector('.form-wrapper');
        if(steps !== nextStep) {
            formWrapper.classList.add('hidden');
            setTimeout(() => {
                this.props.proceedToStep(nextStep);
            }, 300);
        }else {
            formWrapper.classList.remove('hidden');
        }
    };

    render() {
        const {step, stepHeader} = this.props;

        return (
            <div
                className="tile-wrapper"
                data-step={step}
                onClick={() => {this.handleStepSwitch(step)}}
            >
                <p>{stepHeader}</p>
                <div className="tile">
                    <div className="progress" />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {state}
};

const mapDispatchToProps = dispatch => {
    return {
        proceedToStep: (payload) => dispatch(proceedToStepAction(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StepsTile);
